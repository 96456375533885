@import "../../assets/css/media_variables";
@import "../../assets/css/colors";
.screen-wrapper {
  font-family: "HelveticaNeue", "Arial", sans-serif; // todo: don't forget to remove and move to basic styles

  &__line {
    display: flex;
    //flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
  }
  &__section {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;

    &--nopadding {
      padding-top: 0;
    }

    &--rotate {
      flex-direction: column;
    }

    &:last-child {
      border-bottom: none;
    }

    &--noborder {
      border-bottom: none;
    }
  }
}

.screen-wrapper-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  &--no100width {
    width: auto;
  }

  &--nospacing {
    .screen-wrapper-column__title {
      margin-bottom: 6px;
    }
  }

  &--nobottomspacing {
    margin-bottom: 0;
  }

  &__title,
  &__label {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 13px;
  }

  &__regular-label {
    margin-bottom: 12px;
    font-size: 12px;
  }

  &__screen-label {
    margin-bottom: 12px;
    font-size: 12px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    color: $brownish-grey;
  }

  &__value {
    margin-bottom: 14px;
    font-size: 12px;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text-field {
    margin: 0 15px;
    &--left-margin {
      margin-left: 17px;
    }

    &--right-margin {
      margin-right: 17px;
    }
  }

  &__select-field {
    width: 192px;
  }

  &__button {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 20px;
    align-items: center;
    &__loader {
      animation: spin 1s infinite linear;
      width: 16px;
      height: 16px;
    }
    button {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      display: inline-block;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      color: #fff;
      background-color: #62bdb5;
      border: 1px solid #62bdb5;
      border-radius: 4px;
      padding: 8px 14px;
      margin: 4px 0px;
      outline: none;
      cursor: pointer;
      min-width: 100px;
      font-size: 12px;
      height: 32px;

      &:focus-within {
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.screen-wrapper__screen__title + div {
  padding: 15px 0px;
}

.screen-wrapper__actions {
  button {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #fff;
    background-color: #62bdb5;
    border: 1px solid #62bdb5;
    border-radius: 4px;
    padding: 8px 14px;
    margin: 4px 0;
    outline: none;
    cursor: pointer;
    min-width: 100px;
    font-size: 12px;
    height: 32px;

    &:focus-within {
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &__loader {
    animation: spin 1s infinite linear;
    width: 16px;
    height: 16px;
  }
  .button {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  &--modal {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

.screen-wrapper__error__title {
  font-size: 12px;
  font-family: "Lucida Grande";
  color: $red-color;
  display: flex;
  padding-top: 6px;
  margin: 0 15px;

  @media (max-width: $mobile) {
    padding: 0;
  }
}
