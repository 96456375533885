@import "../../assets/css/colors";

.profile-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 960;

  &--disabled {
    display: none;
  }

  &__body {
    background: #fff;
    position: absolute;
    width: 300px;
    height: 199px;
    top: 63px;
    right: 15px;
    z-index: 990;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.24);
  }

  &__overlay,
  &__close {
    cursor: pointer;
  }

  &__overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 970;
  }

  &__header {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    height: 98px;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 12px;
      height: 48px;
      overflow: hidden;
    }
  }

  &__title-email {
    font-weight: 500;
    font-size: 12px;
    color: $slate-grey;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__title-name {
    font-weight: 600;
    font-size: 14px;
    color: $blue-gray;
    text-decoration: none;
  }

  &__image {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    height: 101px;
    justify-content: center;

    &__button {
      padding-top: 20px;
      padding-bottom: 30px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
