@import "../../../assets/css/colors";

.checkbox {
  &__wrapper {
    display: flex;
    margin-bottom: 14px;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0;
    user-select: none;
    color: $black;

    &__error {
      color: $light-sage;
      margin-bottom: 0;
    }
  }
  &__input {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    &:checked {
      & ~ .checkbox {
        &__mark {
          border-color: $greyblue;
          background-color: $greyblue;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            content: "";
            border: 0 solid $clear-white;
            width: 2px;
            height: 5px;
            border-bottom-width: 2px;
            border-right-width: 2px;
            display: block;
            transform: rotateZ(45deg);
          }
        }
      }
    }
  }
  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__mark {
    display: block;
    margin-right: 15px;
    height: 10px;
    width: 10px;
    border: 1px solid $black;
    border-radius: 2px;
    flex-shrink: 0;
  }
  &__icon {
    width: auto;
    max-height: 22px;
    padding-right: 8px;
  }
}
