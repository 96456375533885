@import "../../assets/css/media_variables.scss";

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 1rem;
  @media (max-width: $laptop) {
    padding: 0 0.5rem;
  }
  @media (max-width: $mobile) {
    padding: 0 1rem;
  }
}
