@import "../../assets/css/colors";

.measurement_values {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__horizontal {
    flex-direction: row;
  }

  &__vertical {
    flex-direction: column;
  }

  &__item {
    display: flex;
    padding-top: 10px;
    margin: 0 15px;

    &__left-margin {
      margin-left: 30px;
    }

    &__icon {
      display: flex;
      width: 24px;
      height: 23px;
      align-self: center;
    }

    &__data_picker {
      padding-left: 25px;
      padding-right: 25px;
    }

    &__list {
      flex-direction: row;
      display: flex;

      &__container {
        flex-direction: column;
        display: flex;
        padding-left: 20px;
        //padding-top: 10px;

        &__label {
          font-size: 13px;
          cursor: pointer;
          font-weight: 600;
          padding-bottom: 6px;
        }

        &__value {
          width: 67px;
        }
      }
    }
  }
}
