$clear-white: #ffffff;
$white: #fbfbfb;
$ice-blue: #eef1f2;
$pale-grey: #eef2f8;
$powder-blue: #b6e0f8;
$beige: #f8cf9e;
$light-sage: #cbe0ad;
$greyblue: #62bdb5;
$greyblue-hover: #5eb9b1;
$very-light-pink: #aeaeae;
$very-light-pink-two: #efefef;
$very-light-pink-three: #dddddd;
$brown-grey: #a6a6a6;
$brown-grey-two: #999999;
$brown-grey-fourth: #dee5e8;
$brown-grey-three: #979797;
$brownish-grey: #777777;
$slate-grey: #646b6f;
$black: #333333;
$light-gray: #999999;
$border: #cccccc;
$disable-border: #dedede;
$input-text: #555555;
$line-color: #e5e5e5;
$blue-gray: #627595;
$red-color: #eb5028;
$white-three: #dddddd;
$gray-blue: 0 0 5px 0 rgba(120, 173, 228, 0.76);
$lightBlue: #78ade4;
$lightPink: #d96865;
$orange: #f2c94c;
$light_gray-blue: #d5e0f4;
$title-light-gray: #5c5c5c;
$header-description-text: rgba(51, 51, 51, 0.6);
