@import "../../../assets/css/colors";

.radio {
  &__wrapper {
    display: flex;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0;
    user-select: none;
    &__error {
      color: $light-sage;
    }
  }
  &__input {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    &:checked ~ .radio__mark {
      border: 3px solid $greyblue;
      height: 4px;
      width: 4px;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__mark {
    display: block;
    margin-right: 8px;
    height: 8px;
    width: 8px;
    border: 1px solid $brown-grey;
    border-radius: 50%;
  }
}
