@import "../../assets/css/colors";
@import "../../assets/css/media_variables";

.screen-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px $very-light-pink-three;
  width: 100%;
  //min-height: 200px;
  background-color: $clear-white;
  margin-bottom: 15px;

  @media (max-width: $mobile) {
    margin-bottom: 4px;
  }

  &__left-line {
    width: 7px;
    background-color: $powder-blue;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__screen {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    @media (max-width: $mobile) {
      padding: 0;
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      height: 35px;
      border-bottom: 1px solid #f1eff1;

      @media (max-width: $mobile) {
        margin: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }

      &__label {
        align-self: center;
        opacity: 0.6;
        padding-left: 10px;
        font-family: "Lato", sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: #333333;

        @media (max-width: $mobile) {
          padding-left: 15px;
        }
      }
    }
  }
}
