@import "../../../assets/css/colors";
@import "../../../assets/css/media_variables.scss";

.datetime-container {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    font-size: 13px;
    color: $black;
    padding-bottom: 6px;
    line-height: 16px;
  }

  &__inner {
    display: flex;
    flex-grow: 1;
    flex-direction: row;

    &__icon {
      display: flex;
      height: 32px;
      width: 39px;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top: solid 1px $border;
      border-bottom: solid 1px $border;
      border-left: solid 1px $border;

      &__image {
        display: flex;
        height: 13px;
        width: 13px;
      }
    }
  }
  &__error {
    position: relative;
    top: 4px;
    font-size: 12px;
    color: #eb5028;
  }
}

.datetime-picker {
  height: 30px;
  width: 166px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #cccccc;
  font-family: Lato, sans-serif;
  font-size: 12px;
  color: $black;
  text-align: center;
  outline: none;
  &:focus {
    box-shadow: $gray-blue;
    border-color: $lightBlue;
  }
  &.error {
    border: solid #eb5028 1px;
    border-radius: 4px;
  }
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}
