@import "../../assets/css/colors";
@import "../../assets/css/media_variables";

.measurement_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 21px;
  margin-bottom: 10px;
  margin: 0 15px;
  @media (max-width: $mobile) {
    margin-top: 1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-right: 30px;
    min-width: 70px;

    @media (max-width: $mobile) {
      padding-right: 35px;
    }

    &__icon {
      width: 24px;
      height: 23px;
      padding-left: 10px;
    }

    &__title {
      padding-left: 8px;
      font-size: 12px;
      color: $black;
    }
  }
}
