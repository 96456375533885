@import "../../assets/css/colors";
@import "../../assets/css/media_variables";

.header {
  display: flex;
  width: 100%;
  height: 63px;
  align-items: center;
  justify-content: space-between;

  .button {
    @media (max-width: $mobile) {
      padding: 10px 7px;
    }
  }

  &__left-container {
    display: flex;
    align-items: center;
  }
  &__title-text {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.22px;
    color: black;
    margin-left: 9px;
    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }
  &__icon {
    height: 12px;
    width: 12px;
  }

  // LOGOUT BUTTON
  &__logout {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    &__profile-icon {
      height: 35px;
      width: 35px;
      border-radius: 17.5px;
    }

    &__icon {
      margin-left: 17px;
      height: 8px;
      width: 8px;
      @media (max-width: $mobile) {
        margin-left: 0.5rem;
      }
    }
  }
  &__logout_text {
    font-size: 13px;
    color: $slate-grey;
    margin-left: 9px;
  }
  &__logout:hover {
    opacity: 0.9;
  }
  &__logout:active {
    opacity: 0.6;
  }
  .tooltip-wrapper_secondary {
    &.toltip-down {
      top: 27px;
    }
  }
}
