@import "../../../assets/css/colors";
@import "../../../assets/css/media_variables";

.chainInfo_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 18px;
  padding-bottom: 20px;

  @media (max-width: $mobile) {
    padding: 0;
    margin-top: -14px;
  }

  &__more_container {
    display: flex;
    padding-top: 10px;
  }

  &__row {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    padding-bottom: 10px;

    &__column {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      min-width: 320px;

      &-border {
        border-width: 0 0 1px 0;
        border-color: $white-three;
        border-style: solid;
      }

      @media (max-width: $mobile) {
        max-width: 50%;
        width: 50%;
      }

      &__title {
        padding-top: 14px;
        font-family: "Lato Bold";
        font-size: 13px;
        font-weight: bold;
        color: $black;

        &__first {
          margin-top: 6px;

          @media (max-width: $mobile) {
            margin-top: 0;
          }
        }
      }

      &__value {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 5px;
        font-family: Lato;
        font-size: 12px;
        color: $black;

        border-width: 0 1px 0 0;
        border-color: $white-three;
        border-style: solid;
      }

      &__link {
        padding-left: 10px;
        padding-right: 3px;
        margin-top: 5px;
        font-family: "Roboto Medium", serif;
        font-size: 12px;
        color: $blue-gray;
      }

      &__more-title {
        margin-right: 8px;
        font-family: "Roboto Medium", serif;
        font-size: 12px;
        color: $blue-gray;
      }

      &__value:last-child {
        border-width: 0;
      }

      &__value:first-child {
        padding-left: 0;
      }

      &__between_vale {
        padding-top: 7px;
      }
    }

    &__column:last-child {
      border-width: 0;
    }
  }
}

.rotated {
  transform: rotate(180deg);
}
