@import "../../../assets/css/colors";

.select {
  width: 100%;
  padding: 7px;
  height: 34px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px $border;
  background-color: $clear-white;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $input-text;
  outline: none;

  &:disabled {
    color: $light-gray;
    border: solid 1px $disable-border;
    box-shadow: none;
  }
  &__error {
    border: solid 1px $red-color;
  }
}
