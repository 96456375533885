.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 960;

  &--disabled {
    display: none !important;
  }

  &__body {
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 990;
    min-width: 280px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.51);
  }

  &__overlay,
  &__close {
    cursor: pointer;
  }

  &__overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: #000;
    opacity: 0.4;
    z-index: 970;
  }

  &__close {
    color: #cccccc;
  }

  &__header {
    height: 18px;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-right: 1rem;
  }

  &__wrapper {
    padding: 16px 16px 20px;

    p {
      font-size: 12px;
      line-height: 1.42;
      color: #333;
      margin-bottom: 8px;
    }
  }
  &__close-icon {
    width: 10px;
  }
}
