@import "../../assets/css/media_variables.scss";

.task-timer-panel {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(98, 117, 149, 0.49);
  align-self: center;
  border-radius: 8px;
  bottom: 20%;
  position: absolute;
  @media (max-width: $mobile) {
    position: static;
    margin-top: 70px;
  }
  &__wrapper {
    height: 100%;
  }
  &__time {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.21913px;
    text-transform: uppercase;
  }
  &__message {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.21913px;

    color: rgba(51, 51, 51, 0.85);
  }
  &__finish {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.21913px;
    text-decoration-line: underline;

    /* Blue */

    color: #627595;
  }
}
