// Roboto
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Black.ttf") format("truetype");
  src: url("..//fonts/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Thin.ttf") format("truetype");
  src: url("../fonts/roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

// Lato
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Black.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Hairline.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-HairlineItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Light.ttf") format("truetype");
  src: url("../fonts/lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 200;
}

