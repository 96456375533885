@import "./colors";

.application--print {
  .content {
    max-width: 1020px;
    margin: 0 auto;
  }

  .patient_container {
    padding: 0;
  }

  .screen-wrapper {
    &__left-line {
      display: none;
    }

    &__screen__title {
      border-bottom: 1px solid #e5e5e5 !important;
    }

    &__screen {
      padding: 0;
    }
  }

  .soap-notes-wrapper {
    box-sizing: border-box;
  }

  .measurements-view {
    display: none;
  }
}

.header--print {
  width: 1020px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }

  .print-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.22px;
    color: #8c9296;
  }
}

.case-print {
  &-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    color: $slate-grey;

    p {
      opacity: 0.7;
      font-family: Lato;
      font-size: 12px;
    }
  }

  &__left {
    strong {
      font-family: Lato;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.22px;
      text-transform: uppercase;
    }

    p {
      margin-top: 5px;
      margin-bottom: 0;
      font-weight: 800;
    }
  }

  &__right {
    p {
      font-weight: 600;
      margin-top: 5px;
    }
  }
}
