@import "../../assets/css/colors";
@import "../../assets/css/media_variables";

.task-timer-form {
  max-width: 588px;
  &__description-item {
    &__label {
      font-size: 13px;
      line-height: 16px;
    }

    &__text {
      font-size: 12px;
      line-height: 14px;
      margin-top: 5px;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .datetime-picker {
    width: 119px;
    border-radius: 4px;
    width: calc(100% - 6px);
  }
  textArea {
    font-family: inherit;
    min-height: 118px;
    min-width: 280px;
    max-height: 350px;
    max-width: 562px;
    width: 562px;
  }
  @media (max-width: $mobile) {
    textArea {
      max-width: 320px;
      width: 320px;
    }
  }
  @media (max-width: 390px) {
    textArea {
      min-width: auto;
      max-width: 260px;
      width: 260px;
    }
  }
  .datetime-container__error {
    position: relative;
    top: 4px;
    font-size: 12px;
    color: #eb5028;
  }
}
