@import "../../../assets/css/colors";

.form-row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 14px;
  &__error {
    margin-bottom: 0;
  }
}

.label {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 13px;
  color: $black;
}

.label-disabled {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 13px;
  color: $light-gray;
}
.text__error {
  position: relative;
  top: 4px;
  font-size: 12px;
  color: $red-color;
}
