@import "../../../assets/css/colors";

.button {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  display: inline-block;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 14px;
  margin: 4px 0;
  outline: none;
  cursor: pointer;
  min-width: 82px;
  font-size: 12px;

  &:focus-within {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &__default {
    border-color: $greyblue;
    color: $greyblue;
  }

  &__primary {
    border-color: $greyblue;
    background-color: $greyblue;
    color: $clear-white;
  }
  &__ml-8 {
    margin-left: 2rem;
  }
}

.logout-button {
  margin-right: 14px;
  &:last-child {
    margin-right: 0;
  }
}
