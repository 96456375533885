@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  &__block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fffc;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
  }
  &__element {
    width: 48px;
    height: 48px;
    animation: spin 1s infinite linear;
    transform-origin: center;
  }
}
