@import "../../assets//css/colors.scss";
.toaster-wrapper {
  border-radius: 4px !important;
  &__content {
    color: #fff;
  }
  &_success {
    background: #62bdb5 !important;
  }
  &_error {
    background: #eb5028 !important;
  }
  &_warning {
    background: $orange !important;
  }
}

.Toastify {
  &__close-button--default {
    color: #fff !important;
    opacity: 0.7 !important;
  }
}
