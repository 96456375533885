@import "../../assets/css/colors.scss";

.application {
  display: flex;
  background-color: $ice-blue;
  height: 100%;

  &--print {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
