@import "./media_variables";

@media (max-width: $mobile) {
  .application {
    flex-direction: column;
  }

  .content {
    overflow: hidden;
    padding: 0;
    min-height: calc(100vh - 63px);
  }

  .header {
    background-color: transparent;
  }

  .hidden-mobile {
    display: none !important;
  }

  .navigation {
    z-index: 920;

    &__wrapper {
      width: 100%;
      height: 60px;
      overflow: hidden;
      background: #fbfbfb;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);

      &__background {
        z-index: 910;
      }
    }

    &__burger {
      margin: 22px 0 16px 22px;
      width: 18px;
      height: 16px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        background-color: #b0b3b5;
        height: 2px;
        width: 100%;
        border-radius: 0;
      }
    }

    &__headline {
      height: 60px;
      position: relative;
    }

    &__logo {
      &--mobile {
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
      }
    }
  }

  .pagination__select__wrapper {
    display: none !important;
  }
}

@media (min-width: $mobile) {
  .hidden-desktop {
    display: none !important;
  }
}
