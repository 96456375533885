.case__soap-notes {
  .screen-wrapper-column__value {
    margin-bottom: 20px;
  }
}

.measurements-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-top: 28px;
}

.measurement-item {
  display: flex;
  align-items: center;
  margin-right: 40px;

  &-nomargin {
    margin-right: 0;
  }

  img {
    height: 18px;
    margin-right: 10px;
  }

  span {
    font-size: 12px;
    line-height: 18px;
  }
}

.cases {
  .table__head {
    .table__row {
      border-top: none;
    }
  }
}
