@import "../../../assets/css/colors";

.text {
  &__wrapper {
    display: flex;
    margin-bottom: 17px;
    letter-spacing: 0;
    flex-direction: column;
    &__error {
      margin-bottom: 3px;
    }
  }
  &__input {
    &:disabled {
      color: $light-gray;
      border: solid 1px #dedede;
      box-shadow: none;
      opacity: 0.7;
    }
    position: relative;
    font-size: 14px;
    outline: none;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px $border;
    background-color: $clear-white;

    &:focus {
      box-shadow: 0 0 5px 0 rgba(120, 173, 228, 0.76),
        inset 0 2px 2px 0 rgba(0, 0, 0, 0.07);
      border-color: #78ade4;
    }
  }
  &__error_input {
    font-size: 14px;
    outline: none;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(235, 80, 40, 0.18),
      inset 0 2px 2px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #eb5028;
    background-color: $clear-white;

    &:focus {
      box-shadow: 0 0 5px 0 rgba(120, 173, 228, 0.76),
        inset 0 2px 2px 0 rgba(0, 0, 0, 0.07);
      border-color: #78ade4;
    }
  }
  &__label {
    &.disabled {
      color: $light-gray;
    }
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
    padding-bottom: 6px;
  }
  &__error {
    position: relative;
    top: 4px;
    font-size: 12px;
    color: #eb5028;
  }
  &__inside-label {
    right: 0;
    bottom:0;
    z-index: 10;
    padding: 10px 12px 10px 0;
    font-size: 14px;
    color: #757575;
  }
}
