@import "../../assets/css/colors";
@import "../../assets/css/media_variables";

.navigation {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $white;
  width: 228px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  @media (max-width: $mobile) {
    width: 90%;
    position: absolute;
    left: -100%;
    transition: 0.3s linear all;
    z-index: 100;
    box-shadow: none;
    top: 60px;
    height: 100%;
  }

  &__top-bar {
    display: flex;
    align-items: center;
    height: 90px;
    justify-content: space-between;
  }
  &__top-bar-icon-back {
    height: 12px;
    padding: 13px 15px 13px 15px;
    margin-left: 16px;
  }
  &__top-bar-icon-back:hover {
    border-radius: 19px;
    background-color: $very-light-pink-two;
  }
  &__top-bar-icon-back:active {
    border-radius: 19px;
    background-color: $very-light-pink-two;
  }
  &__top-bar-icon-logo {
    height: 45px;
    margin-left: 5px;
  }

  &__link {
    display: flex;
    padding: 16px 16px 16px 62px;
    text-decoration: none;
    color: $slate-grey;
    opacity: 0.7;
    font-size: 14px;
    letter-spacing: 0.17px;
    position: relative;

    &:hover,
    &.active {
      opacity: 1;
      font-weight: 600;

      &:before {
        content: " ";
        width: 5px;
        height: 5px;
        background: #627595;
        border-radius: 50%;
        position: absolute;
        left: 41px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__group {
    background-color: $pale-grey;
    opacity: 1;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    border-left: 4px solid rgba(74, 128, 217, 0.16);
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 30px;

    img {
      margin-right: 10px;
    }
  }

  &__icon {
    width: 12px;
    height: 12px;
    padding-right: 18px;
  }

  &__burger {
    padding: 8px 8px;
    height: 16px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 98;

    span {
      width: 16px;
      height: 4px;
      display: block;
      background-color: $greyblue;
      border-radius: 2px;
    }

    @media (max-width: $mobile) {
      display: flex;
    }
  }

  &__wrapper {
    @media (max-width: $mobile) {
      &__background {
        transition: 0.1s linear opacity;
        opacity: 0;
      }

      &.open {
        overflow: visible;
        .navigation {
          left: 0;

          &__wrapper {
            &__background {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 99;
              background-color: $black;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  &__logo {
    &--mobile {
      display: none;
    }
  }
}
