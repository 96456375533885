@import "../../assets/css/colors";
@import "../../assets/css/media_variables";

.reset-password {
  min-width: 320px;
  &__input_element {
    padding-bottom: 12px;
    width: 100%;
  }
  &__input_password {
    position: relative;
    .icon-wrapper {
      position: absolute;
      right: 5%;
      top: 0;
      height: 81px;
    }
    img {
      margin-top: 31px;
    }
  }
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .buttons-wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    &__button {
      border-radius: 4px;
      border: 1px solid $greyblue;
      color: $greyblue;
      margin-right: 10px;
      height: 38px;
      width: 100%;
      max-width: 91px;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      background: transparent;
      &_primary {
        color: #fff;
        border-color: $greyblue;
        background-color: $greyblue;
      }
      &:disabled {
        color: $clear-white;
        background-color: $greyblue;
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: $mobile) {
        width: 80vw;
      }
    }

    &__button:hover {
      outline: none;
      background-color: $greyblue-hover;
      color: #fff;
    }
    &__button:focus {
      outline: none;
      background-color: $greyblue-hover;
      color: #fff;
    }
  }
}
